import { QuestionCircleTwoTone } from "@ant-design/icons";
import { Button, Card, Col, Flex, Spin, Tag, Tooltip, Typography } from "antd";
import { Service } from "../../../entities/service/Service";
import { getTag } from "../scripts/getTag";

type ServiceCardProps = {
  service: Service;
  stopService: (serviceId: number) => void;
  startService: (serviceId: number) => Promise<void>;
  openServiceModal: (serviceId: number) => void;
  canActivate: boolean;
};

export const ServiceCard = ({
  service,
  stopService,
  startService,
  openServiceModal,
  canActivate,
}: ServiceCardProps) => {
  const tag = getTag(service.status);

  const { description } = service.defaultService;

  let actionButtons: React.ReactNode;
  if (!service.canActivate) {
    actionButtons = null;
  } else {
    actionButtons =
      service.status === "active" ? (
        <Button
          disabled={!service.isActive || !canActivate}
          onClick={() => stopService(service.id)}
        >
          Остановить
        </Button>
      ) : (
        <Button
          disabled={!service.isActive || !canActivate}
          onClick={() => startService(service.id)}
        >
          Запустить
        </Button>
      );
  }

  return (
    <Col span={12} key={service.id}>
      <Card
        title={
          <Flex align="center" gap="0.3rem">
            <Flex style={{ flexDirection: "column", margin: "1rem 0" }}>
              <Typography.Title level={4} style={{ marginBottom: 0 }}>
                {service.defaultService.name}
              </Typography.Title>
              {service.user?.email && (
                <Typography.Text>{service.user.email}</Typography.Text>
              )}
            </Flex>

            {description && (
              <Tooltip
                color="white"
                title={
                  <div
                    style={{ color: "black" }}
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                }
              >
                <QuestionCircleTwoTone />
              </Tooltip>
            )}
          </Flex>
        }
        extra={
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              {/* {service.status === "active" && service.percent != null && (
                <Typography.Text>{service.percent}%</Typography.Text>
              )} */}
              {service?.status === "active" && <Spin />}
              {tag && <Tag color={tag.color}>{tag.status}</Tag>}
            </div>
          </>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          {service.defaultService.title === "abc" ? (
            <Button
              type="primary"
              href={`/reports/${service.defaultService.id}`}
              target="_blank"
            >
              Открыть
            </Button>
          ) : (
            <>
              {actionButtons}
              {Object.keys(service.schema).length ? (
                <Button onClick={() => openServiceModal(service.id)}>
                  Настройки
                </Button>
              ) : null}
              {service?.spreadsheetId && !service.isInner && (
                <Button
                  type="primary"
                  href={
                    service.spreadsheetId.startsWith("http")
                      ? service.spreadsheetId
                      : service.spreadsheetLink
                  }
                  target="_blank"
                >
                  Открыть
                </Button>
              )}
            </>
          )}
        </div>
      </Card>
    </Col>
  );
};
