export const getTag = (
  status: "active" | "inactive" | "error" | "done" | "stopped" | undefined
) => {
  switch (status) {
    case "active":
      return {
        color: "yellow",
        status: "Заполняется",
      };

    case "stopped":
      return {
        color: "gray",
        status: "Остановлен",
      };
    // case "error":
    //   return {
    //     color: "red",
    //     status: "Ошибка",
    //   };
    // case "inactive":
    //   return {
    //     color: "orange",
    //     status: "Готов к работе",
    //   };
    case "done":
      return {
        color: "green",
        status: "Заполнен",
      };
    default:
      return null;
    // return {
    //   color: "gray",
    //   status: "Не подключен",
    // };
  }
};
