export const checkIsSettingsSetted = (
  schema: Record<string, any>,
  settings: Record<string, any>
) => {
  const properties = schema.properties;
  const isPropertiesExist =
    typeof schema.properties === "object" &&
    Object.keys(schema.properties).length;
  const requiredPropertyNames = schema.required;
  if (!isPropertiesExist) return true;
  if (!requiredPropertyNames?.length) return true;

  for (const propertyName in properties) {
    const isPropertyRequired = requiredPropertyNames.includes(propertyName);
    if (!isPropertyRequired) continue;

    const property = properties[propertyName];

    const isPropertyArray =
      property.type === "array" && Array.isArray(property.default);
    if (isPropertyArray) {
      if (
        !property.default.length ||
        !settings[propertyName] ||
        !settings[propertyName].length
      ) {
        return false;
      }
      continue;
    }

    const isPropertyPrimitive =
      property.type === "number" || property.type === "string";
    if (isPropertyPrimitive) {
      if (!property.default && !settings[propertyName]) {
        return false;
      }
      continue;
    }
  }
  return true;
};
