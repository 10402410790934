import { Modal } from "antd";
import { useState } from "react";

type SharedModalProps = {
  children?: React.ReactNode;
  handleSubmit?: () => Promise<void>;
  title: string;

  isVisible: boolean;
  closeModal: () => void;

  cancelText?: string;
  okText?: string;
};

export const SharedModal: React.FC<SharedModalProps> = ({
  children,
  handleSubmit,
  title,
  isVisible,
  closeModal,

  cancelText = "Отмена",
  okText = "Подтвердить",
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    if (!handleSubmit) {
      return;
    }
    setConfirmLoading(true);
    await handleSubmit();
    setConfirmLoading(false);
  };

  return (
    <Modal
      title={title}
      open={isVisible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={closeModal}
      footer={null}
      // cancelText={cancelText}
      // okText={okText}
    >
      {children}
    </Modal>
  );
};
