import { useCreate, useGetIdentity, useList, useModal } from "@refinedev/core";
import Form from "@rjsf/antd";
import { IChangeEvent } from "@rjsf/core";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { Row, Spin, Typography } from "antd";
import { useState } from "react";
import { TreeComponent } from "../../components/TreeComponent";
import { DefaultService } from "../../entities/service/DefaultService";
import { Service } from "../../entities/service/Service";
import { User } from "../../entities/user/User";
import { SharedModal } from "../../shared/ui/SharedModal";
import { ServiceCard } from "../reports/components/ServiceCard";
import { useStopService } from "../../entities/service/lib/useStopService";
import { useParams } from "react-router-dom";
import { checkIsSettingsSetted } from "../../features/checkIsSettingsSetted/checkIsSettingsSetted";
import { checkIsProductFoldersSetted } from "../../features/checkIsProductFoldersSetted/checkIsProductFoldersSetted";

const uiSchema: UiSchema = {
  "ui:submitButtonOptions": {
    props: {
      type: "primary",
    },
    norender: false,
    submitText: "Сохранить настройки",
  },
};

export const AdminPage = () => {
  const params = useParams();
  const userId = params.id;

  const [productFolders, setProductFolders] = useState<
    { key: string; title: string }[]
  >([]);
  const [currentService, setCurrentService] = useState<Service | null>(null);
  const [isCurrentServiceLoading, setIsCurrentServiceLoading] = useState(false);
  const {
    visible: isModalVisible,
    show: showModal,
    close: closeModal,
  } = useModal();
  const productFoldersModal = useModal();
  const { data: user, refetch: refetchUser } = useGetIdentity<User>();
  const defaultServicesQuery = useList<DefaultService>({
    resource: "default-services",
  });
  const userServicesQuery = useList<Service>({
    resource: userId ? `services?user-id=${userId}` : "services",
    pagination: {
      mode: "off",
    },
    queryOptions: {
      refetchInterval: 5_000,
    },
  });

  const openServiceModal = (serviceId: number) => {
    const service = userServicesQuery.data?.data.find(
      (service) => service.id === serviceId
    );
    if (!service) {
      throw new Error(`Не найден сервис ${serviceId}`);
    }

    setCurrentService(service);
    if (service?.defaultService?.isWithProductFolders) {
      productFoldersModal.show();
      return;
    }
    showModal();
  };

  const { mutateAsync: create } = useCreate();

  const setServiceSettings = async (
    service: Service,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    submittedForm: IChangeEvent<any, RJSFSchema, any>
  ) => {
    const { formData } = submittedForm;

    await create({
      resource: `services/settings?service-id=${service.id}`,
      values: {
        settings: formData,
        productFolders: productFolders,
      },
      successNotification: {
        type: "success",
        message: "Настройки сохранены",
      },
      errorNotification: {
        type: "error",
        message: "Не удалось сохранить настройки",
      },
    })
      .catch((error) => {
        console.error(error);
      })
      .then(() => {
        refetchUser();
        closeModal();
      });
  };
  const startService = async (serviceId: number) => {
    await create({
      resource: `services/start?service-id=${serviceId}`,
      values: {
        isActive: true,
        // settings: formData,
        // productFolders: productFolders,
      },
      successNotification: {
        type: "success",
        message: "Сервис включен",
      },
      errorNotification: {
        type: "error",
        message: "Не удалось подлкючить сервис",
      },
    }).catch((error) => {
      console.error(error);
    });
    refetchUser();
    closeModal();
  };

  const stopService = useStopService();

  const handleStopService = (serviceId: number) => {
    stopService(serviceId)
      .then(() => {
        userServicesQuery.refetch();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (defaultServicesQuery.isLoading || userServicesQuery.isLoading || !user) {
    return null;
  }

  const services = userServicesQuery.data?.data;
  const serviceElements = services?.map((service) => (
    <ServiceCard
      key={service.id}
      startService={startService}
      service={service}
      openServiceModal={openServiceModal}
      stopService={() => handleStopService(service.id)}
      canActivate={
        checkIsSettingsSetted(service.schema, service.settings) &&
        checkIsProductFoldersSetted(
          service.defaultService.isWithProductFolders,
          service.productFolders
        )
      }
    />
  ));

  return (
    <>
      <div>
        <Typography.Title level={3}>Все сервисы</Typography.Title>
        {/* <Typography.Title level={4}>
          Необходимы для работы отчётов
        </Typography.Title> */}
        <Row gutter={[16, 16]} style={{ marginBottom: "2rem" }}>
          {serviceElements}
        </Row>
      </div>

      {currentService?.defaultService?.isWithProductFolders && (
        <SharedModal
          closeModal={productFoldersModal.close}
          isVisible={productFoldersModal.visible}
          handleSubmit={async () => showModal()}
          title={currentService.name}
          okText="Продолжить"
        >
          <Spin spinning={isCurrentServiceLoading}>
            <TreeComponent
              alreadySelectedNodes={currentService.productFolders}
              handleContinue={(savedNodes) => {
                setProductFolders(savedNodes);

                productFoldersModal.close();
                showModal();
              }}
            />
          </Spin>
        </SharedModal>
      )}
      {currentService && (
        <SharedModal
          closeModal={closeModal}
          isVisible={isModalVisible}
          handleSubmit={async () => {}}
          title={currentService.name}
          okText="Сохранить настройки"
        >
          <Spin spinning={isCurrentServiceLoading}>
            <Form
              uiSchema={uiSchema}
              schema={currentService.schema}
              validator={validator}
              onSubmit={async (formData) => {
                setIsCurrentServiceLoading(true);
                await setServiceSettings(currentService, formData);
                setIsCurrentServiceLoading(false);
              }}
            />
          </Spin>
        </SharedModal>
      )}
    </>
  );
};
