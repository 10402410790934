import { Authenticated } from "@refinedev/core";

import { ErrorComponent, ThemedLayoutV2, ThemedSiderV2 } from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import {
  CatchAllNavigate,
  NavigateToResource,
} from "@refinedev/react-router-v6";
import { Outlet, Route, Routes } from "react-router-dom";
import { ForgotPasswordPage, LoginPage, RegisterPage } from "../pages";
import { UpdatePasswordPage } from "../pages/UpdatePasswordPage/UpdatePasswordPage";
import { ServicesPage } from "../pages/reports/ServicesPage";
import { UserCreate, UserEdit, UserList, UserShow } from "../pages/users";
import { Logo } from "../shared/ui/Logo";
import { Header } from "../widgets";
import { DataPage } from "../pages/data/DataPage";
import { AdminPage } from "../pages/admin/AdminPage";
import { ServicePage } from "../pages/service/ServicePage";

export const Router = () => {
  return (
    <Routes>
      <Route
        element={
          <Authenticated
            key="authenticated-inner"
            fallback={<CatchAllNavigate to="/login" />}
          >
            <ThemedLayoutV2
              Title={() => (
                <Logo
                  level={2}
                  style={{ marginTop: "1.2rem", marginLeft: "1rem" }}
                />
              )}
              Header={() => <Header sticky />}
              Sider={(props) => <ThemedSiderV2 {...props} fixed />}
            >
              <Outlet />
            </ThemedLayoutV2>
          </Authenticated>
        }
      >
        <Route index element={<NavigateToResource resource="Отчёты" />} />

        <Route path="/reports">
          <Route index element={<ServicesPage />} />
          <Route path=":id" element={<ServicePage />} />
        </Route>

        <Route path="/data">
          <Route index element={<DataPage />} />
        </Route>
        <Route path="/admin">
          <Route index element={<AdminPage />} />
          <Route path=":id" element={<AdminPage />} />
        </Route>
        <Route path="/users">
          <Route index element={<UserList />} />
          <Route path="create" element={<UserCreate />} />
          <Route path="edit/:id" element={<UserEdit />} />
          <Route path="show/:id" element={<UserShow />} />
        </Route>
        <Route path="*" element={<ErrorComponent />} />
      </Route>
      <Route
        element={
          <Authenticated key="authenticated-outer" fallback={<Outlet />}>
            <NavigateToResource />
          </Authenticated>
        }
      >
        <Route path="/login" element={<LoginPage title={<Logo />} />} />
        <Route path="/register" element={<RegisterPage title={<Logo />} />} />
        <Route
          path="/forgot-password"
          element={<ForgotPasswordPage title={<Logo />} />}
        />
        <Route
          path="/update-password/:uuid"
          element={<UpdatePasswordPage title={<Logo />} />}
        />
      </Route>
    </Routes>
  );
};
