import { Typography } from "antd";
import { CSSProperties } from "react";

type LogoProps = {
  level?: 1 | 5 | 2 | 3 | 4 | undefined;
  style?: CSSProperties | undefined;
};
export const Logo = ({ level = 1, style, ...props }: LogoProps) => {
  return (
    <Typography.Title
      level={level}
      {...props}
      style={{ color: "rgb(0, 106, 255)", fontSize: "27px", ...style }}
    >
      БрайтБорд
    </Typography.Title>
  );
};
