import { HttpError } from "@refinedev/core";
import axios from "axios";

export const BASE_URL = import.meta.env.VITE_BACKEND_URL;
export const api = axios.create({
  baseURL: BASE_URL,
});

export const ACCESS_TOKEN_KEY = "access";
export const REFRESH_TOKEN_KEY = "refresh";

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        window.location.replace("/login");
        return Promise.reject();
      }
    }
    const customError: HttpError = {
      ...error,
      name: "Ошибка",
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;

// If the error status is 401 and there is no originalRequest._retry flag,
// it means the token has expired and we need to refresh it
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;

//       try {
//         // const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
//         const response = await api.get("/auth/refresh");
//
//         const { token } = response.data;

//         localStorage.setItem(ACCESS_TOKEN_KEY, token);

//         // Retry the original request with the new token
//         originalRequest.headers.Authorization = `Bearer ${token}`;
//         return api(originalRequest);
//       } catch (error) {
//         // Handle refresh token error or redirect to login
//       }
//     }

//     return Promise.reject(error);
//   }
// );
