export const resources = [
  {
    name: "Отчёты",
    list: "/reports",
  },
  {
    name: "Данные",
    list: "/data",
  },
  {
    name: "Админ",
    list: "/admin",
  },
  {
    name: "Пользователи",
    list: "/users",
    create: "/users/create",
    edit: "/users/edit/:id",
    show: "/users/show/:id",
    meta: {
      canDelete: true,
    },
  },
];
