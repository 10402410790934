import { RefineKbarProvider } from "@refinedev/kbar";
import { BrowserRouter } from "react-router-dom";
import { ColorModeContextProvider } from "./ColorMode";
import { App as AntdApp } from "antd";

type ProvidersProps = {
  children: React.ReactNode;
};

export const Providers: React.FC<ProvidersProps> = ({ children }) => {
  return (
    <BrowserRouter>
      <RefineKbarProvider>
        {/* <ColorModeContextProvider> */}
        <AntdApp>{children}</AntdApp>
        {/* </ColorModeContextProvider> */}
      </RefineKbarProvider>
    </BrowserRouter>
  );
};
