import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd";
import { useGetIdentity } from "@refinedev/core";
import { Layout as AntdLayout, Space, Typography, theme } from "antd";
import React, { useContext } from "react";
import { NotificationsButton } from "../features/notifications/ui/NotificationsButton";
import { ColorModeContext } from "../processes/ColorMode";

const { Text } = Typography;
const { useToken } = theme;

type IUser = {
  id: number;
  email: string;
};

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  sticky,
}) => {
  const { token } = useToken();
  const { data: user } = useGetIdentity<IUser>();
  const { mode, setMode } = useContext(ColorModeContext);

  const headerStyles: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 24px",
    height: "64px",
  };

  if (sticky) {
    headerStyles.position = "sticky";
    headerStyles.top = 0;
    headerStyles.zIndex = 1;
  }

  return (
    <AntdLayout.Header style={headerStyles}>
      <Space>
        {/* <Switch
          checkedChildren="🌛"
          unCheckedChildren="🔆"
          onChange={() => setMode(mode === "light" ? "dark" : "light")}
          defaultChecked={mode === "dark"}
        /> */}
        <Space style={{ marginLeft: "8px" }} size="middle">
          {user?.email && <Text strong>{user.email}</Text>}
        </Space>
        <NotificationsButton />
      </Space>
    </AntdLayout.Header>
  );
};
