import { I18nProvider } from "@refinedev/core";
import { useTranslation } from "react-i18next";

export const useTranslateProvider = () => {
  const { t, i18n } = useTranslation();

  const i18nProvider: I18nProvider = {
    translate: (key: string, defaultMessage: string) => t(key, defaultMessage),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return i18nProvider;
};
