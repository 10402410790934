import { AccessControlProvider } from "@refinedev/core";
import { authProvider } from "./authProvider";
import { User } from "../entities/user/User";

export const useAccessControlProvider = () => {
  const accessControlProvider: AccessControlProvider = {
    can: async ({ resource }) => {
      const user = await authProvider.getIdentity();

      // if (resource === "users") {
      //   return { can: false };
      // }
      if (user?.role === "admin") {
        return { can: true };
      }

      if (resource === "пользователи" || resource === "админ") {
        return { can: false };
      }

      if (resource === "данные" && !user?.canSeeData) {
        return { can: false };
      }
      return { can: true };
    },
    options: {
      buttons: {
        enableAccessControl: true,
        hideIfUnauthorized: true,
      },
    },
  };
  return accessControlProvider;
};
