import { useCreate, useGetIdentity, useList, useModal } from "@refinedev/core";
import Form from "@rjsf/antd";
import { IChangeEvent } from "@rjsf/core";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { Row, Spin, Typography } from "antd";
import { useState } from "react";
import { TreeComponent } from "../../components/TreeComponent";
import { DefaultService } from "../../entities/service/DefaultService";
import { Service } from "../../entities/service/Service";
import { User } from "../../entities/user/User";
import { SharedModal } from "../../shared/ui/SharedModal";
import { ServiceCard } from "../reports/components/ServiceCard";

const uiSchema: UiSchema = {
  "ui:submitButtonOptions": {
    props: {
      type: "primary",
    },
    norender: false,
    submitText: "Сохранить настройки",
  },
};

export const DataPage = () => {
  const [productFolders, setProductFolders] = useState<
    { key: string; title: string }[]
  >([]);
  const [currentService, setCurrentService] = useState<Service | null>(null);
  const [isCurrentServiceLoading, setIsCurrentServiceLoading] = useState(false);
  const {
    visible: isModalVisible,
    show: showModal,
    close: closeModal,
  } = useModal();
  const productFoldersModal = useModal();
  const { data: user, refetch: refetchUser } = useGetIdentity<User>();
  const defaultServicesQuery = useList<DefaultService>({
    resource: "default-services",
  });
  const userServicesQuery = useList<Service>({
    resource: "services/data",
    queryOptions: {
      refetchInterval: 5_000,
    },
  });

  const openServiceModal = (serviceId: number) => {
    const service = userServicesQuery.data?.data.find(
      (service) => service.id === serviceId
    );
    if (!service) {
      throw new Error(`Не найден сервис ${serviceId}`);
    }

    setCurrentService(service);
    if (service?.defaultService?.isWithProductFolders) {
      productFoldersModal.show();
      return;
    }
    showModal();
  };

  const { mutateAsync: create } = useCreate();

  const startService = async (serviceId: number) => {
    await create({
      resource: `services/start?service-id=${serviceId}`,
      values: {
        isActive: true,
        // settings: formData,
        // productFolders: productFolders,
      },
      successNotification: {
        type: "success",
        message: "Сервис включен",
      },
      errorNotification: {
        type: "error",
        message: "Не удалось подлкючить сервис",
      },
    }).catch((error) => {
      console.error(error);
    });
    refetchUser();
    closeModal();
  };

  const stopService = (serviceId: number) => {
    create({
      resource: `services/stop?service-id=${serviceId}`,
      values: {},
      successNotification: false,
      errorNotification: (error) => {
        console.error(error, "error after stop");
        return {
          type: "error",
          message: error?.message ?? "Непредвиденная ошибка",
        };
      },
    })
      .then(() => {
        userServicesQuery.refetch();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (defaultServicesQuery.isLoading || userServicesQuery.isLoading || !user) {
    return null;
  }

  const innerServices = userServicesQuery.data?.data.filter(
    (service) => service.defaultService.isInner
  );

  // const outerServices = userServicesQuery.data?.data.filter(
  //   (service) => !service.defaultService.isInner
  // );

  const innerServiceElements = innerServices?.map((service) => (
    <ServiceCard
      key={service.id}
      startService={startService}
      service={service}
      openServiceModal={openServiceModal}
      stopService={stopService}
      canActivate={user.canActivateServices}
    />
  ));
  // const outerServiceElements = outerServices?.map((service) => (
  //   <ServiceCard
  //     service={service}
  //     openServiceModal={openServiceModal}
  //     stopService={stopService}
  //   />
  // ));
  return (
    <>
      <div>
        <Typography.Title level={3}>Сервисы обслуживания</Typography.Title>
        <Typography.Title level={4}>
          Необходимы для работы отчётов
        </Typography.Title>
        <Row gutter={[16, 16]} style={{ marginBottom: "2rem" }}>
          {innerServiceElements}
        </Row>
      </div>

      {currentService?.defaultService?.isWithProductFolders && (
        <SharedModal
          closeModal={productFoldersModal.close}
          isVisible={productFoldersModal.visible}
          handleSubmit={async () => showModal()}
          title={currentService.name}
          okText="Продолжить"
        >
          <Spin spinning={isCurrentServiceLoading}>
            <TreeComponent
              alreadySelectedNodes={[]}
              handleContinue={(savedNodes) => {
                setProductFolders(savedNodes);

                productFoldersModal.close();
                showModal();
              }}
            />
          </Spin>
        </SharedModal>
      )}
      {currentService && (
        <SharedModal
          closeModal={closeModal}
          isVisible={isModalVisible}
          handleSubmit={async () => {}}
          title={currentService.name}
          okText="Сохранить настройки"
        >
          <Spin spinning={isCurrentServiceLoading}>
            <Form
              uiSchema={uiSchema}
              schema={currentService.schema}
              validator={validator}
              onSubmit={async () => {
                setIsCurrentServiceLoading(true);
                await startService(currentService.id);
                setIsCurrentServiceLoading(false);
              }}
            />
          </Spin>
        </SharedModal>
      )}
    </>
  );
};
