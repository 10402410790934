import { useCreate, useList, useOne, useUpdate } from "@refinedev/core";
import { useParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { DefaultService } from "../../entities/service/DefaultService";
import { Service, ProductFolderNode } from "../../entities/service/Service";

export const useServicePage = () => {
  const [form, setForm] = useState<Record<string, any>>({});
  const [currentService, setCurrentService] = useState<Service | null>(null);
  const [isCurrentServiceLoading, setIsCurrentServiceLoading] = useState(false);
  const [isLoadingServiceHistory, setIsLoadingServiceHistory] = useState(0);
  const [productFolders, setProductFolders] = useState<ProductFolderNode[]>([]);

  const { id: defaultServiceId } = useParams<{ id: string }>();

  const { data: defaultServiceData } = useOne<DefaultService>({
    resource: "default-services",
    id: defaultServiceId,
  });

  const { data: userServicesData, refetch: refetchUserServices } =
    useList<Service>({
      resource: "services/reports",
    });

  const serviceHistory = useList<any>({
    resource: "services-history",
    queryOptions: { refetchInterval: 5000 },
  });

  const { mutateAsync: create } = useCreate();
  // const { mutateAsync: create } = useUpdate();

  useEffect(() => {
    if (!defaultServiceId || !userServicesData?.data) return;

    const service = userServicesData.data.find(
      (service) => service.defaultService.id === +defaultServiceId
    );

    if (service) {
      setCurrentService(service);
      setProductFolders(service.productFolders);
    }
  }, [userServicesData?.data, defaultServiceId]);

  const startService = useCallback(
    async (serviceId: number) => {
      setIsCurrentServiceLoading(true);
      try {
        await create({
          resource: `services/settings?service-id=${serviceId}`,
          values: { settings: form, productFolders },
          successNotification: {
            type: "success",
            message: "Настройки сохранены",
          },
          errorNotification: {
            type: "error",
            message: "Не удалось сохранить настройки",
          },
        });
        await refetchUserServices();

        await create({
          resource: `services/start?service-id=${serviceId}`,
          values: { isActive: true },
          successNotification: { type: "success", message: "Сервис включен" },
          errorNotification: {
            type: "error",
            message: "Не удалось подключить сервис",
          },
        });
      } catch (error) {
        console.error("Ошибка при запуске сервиса:", error);
      } finally {
        setIsCurrentServiceLoading(false);
      }
    },
    [create, form, productFolders, refetchUserServices]
  );

  const stopService = useCallback(
    (serviceId: number, serviceHistoryId: number) => {
      setIsLoadingServiceHistory(serviceHistoryId);
      create({
        resource: `services/stop?service-id=${serviceId}&service-history-id=${serviceHistoryId}`,
        values: {},
        successNotification: false,
        errorNotification: (error) => ({
          type: "error",
          message: error?.message ?? "Непредвиденная ошибка",
        }),
      })
        .then(() => refetchUserServices())
        .then(() => setIsLoadingServiceHistory(0))
        .catch((error) => {
          console.error("Ошибка при остановке сервиса:", error);
          setIsLoadingServiceHistory(0);
        });
    },
    [create, refetchUserServices]
  );

  const removeAllProductFolders = useCallback(async () => {
    if (!currentService) return;

    setIsCurrentServiceLoading(true);
    try {
      await create({
        resource: `services/settings?service-id=${currentService.id}`,
        // id: currentService.id,
        values: { productFolders: [] },
        successNotification: {
          type: "success",
          message: "Все товары очищены",
        },
        errorNotification: {
          type: "error",
          message: "Не удалось очистить товары",
        },
      });

      setProductFolders([]);
      await refetchUserServices();
    } catch (error) {
      console.error("Ошибка при удалении товаров:", error);
    } finally {
      setIsCurrentServiceLoading(false);
    }
  }, [currentService, create, refetchUserServices]);

  return {
    form,
    setForm,
    currentService,
    isCurrentServiceLoading,
    isLoadingServiceHistory,
    productFolders,
    setProductFolders,
    defaultService: defaultServiceData?.data,
    serviceHistory,
    startService,
    stopService,
    removeAllProductFolders,
  };
};
