import { useList, useNotification } from "@refinedev/core";
import { Button, Spin, Tree, TreeProps, Typography } from "antd";
import { DataNode } from "antd/es/tree";
import { useState } from "react";
import { ProductFolderNode } from "../entities/service/Service";

type Props = {
  handleContinue: (selectedNodes: ProductFolderNode[]) => void;
  alreadySelectedNodes: ProductFolderNode[];
};
export const TreeComponent = ({
  handleContinue,
  alreadySelectedNodes,
}: Props) => {
  const productFoldersQuery = useList<DataNode[]>({
    resource: "ms-account/product-folders",
  });
  const [selectedNodes, setSelectedNodes] = useState(alreadySelectedNodes);
  const onSelect: TreeProps["onSelect"] = (selectedKeys, info) => {
    const selectedNodes = info.selectedNodes.map((node) => ({
      key: node.key,
      title: node.title,
    }));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    setSelectedNodes(selectedNodes);
  };

  // const onCheck: TreeProps["onCheck"] = (checkedKeys, info) => {
  //
  // };

  const notification = useNotification();
  const onClick = () => {
    if (!selectedNodes.length) {
      if (!notification.open) return;
      notification.open({
        message: "Не выбрана ни одна категория",
        type: "error",
      });
      return;
    }
    handleContinue(selectedNodes);
  };
  if (productFoldersQuery.isLoading) {
    return <Spin />;
  }

  return (
    <>
      <Spin
        spinning={productFoldersQuery.isLoading || !productFoldersQuery.data}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography.Paragraph>
            Выбранные категории:{" "}
            {selectedNodes.map((node, index) => (
              <Typography.Text key={index}>
                {node.title}
                {index === selectedNodes.length - 1 ? "" : ", "}
              </Typography.Text>
            ))}
          </Typography.Paragraph>
          <Button onClick={onClick}>Продолжить</Button>
        </div>
        <Tree
          multiple
          defaultSelectedKeys={alreadySelectedNodes.map((node) => node.key)}
          // checkable
          selectable
          onSelect={onSelect}
          // onCheck={onCheck}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          treeData={productFoldersQuery.data?.data}
        />
      </Spin>
    </>
  );
};
