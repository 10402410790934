/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AuthActionResponse,
  CheckResponse,
  OnErrorResponse,
  PermissionResponse,
} from "@refinedev/core/dist/interfaces";
import { User } from "../entities/user/User";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, api } from "../shared";

type AuthBindings = {
  login: (params: any) => Promise<AuthActionResponse>;
  logout: (params: any) => Promise<AuthActionResponse>;
  check: (params?: any) => Promise<CheckResponse>;
  onError: (error: any) => Promise<OnErrorResponse>;
  register: (params: any) => Promise<AuthActionResponse>;
  forgotPassword: (params: any) => Promise<AuthActionResponse>;
  updatePassword: (params: any) => Promise<AuthActionResponse>;
  getPermissions?: (params?: any) => Promise<PermissionResponse>;
  getIdentity: () => Promise<null | User>;
};
export const authProvider: AuthBindings = {
  register: async ({ email, password, token }) => {
    if (!token) {
      return {
        success: false,
        error: {
          name: "Ошибка",
          message: "Введите токен",
        },
      };
    }
    if (!(email && password)) {
      return {
        success: false,
        error: {
          name: "Ошибка",
          message: "Неверный email или пароль",
        },
      };
    }

    const response = await api.post<{
      accessToken: string;
      refreshToken: string;
    }>("auth/signup", {
      email,
      password,
      msToken: token,
    });

    if (response.status !== 201) {
      return {
        success: false,
        error: {
          name: "Ошибка",
          message: "Что-то пошло не так",
        },
      };
    }

    const { accessToken, refreshToken } = response.data;
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

    return {
      success: true,
      redirectTo: "/",
    };
  },

  login: async ({ email, password }) => {
    if (!(email && password)) {
      return {
        success: false,
        error: {
          name: "Ошибка",
          message: "Неверный email или пароль",
        },
      };
    }

    const response = await api.post<{
      accessToken: string;
      refreshToken: string;
    }>("auth/signin", {
      email,
      password,
    });

    if (response.status > 400) {
      return {
        success: false,
        error: {
          name: "Ошибка",
          message: "Что-то пошло не так",
        },
      };
    }

    const { accessToken, refreshToken } = response.data;
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

    return {
      success: true,
      redirectTo: "/",
    };
  },

  logout: async () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);

    // api.get("auth/logout");
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (!token) {
      return null;
    }

    const user = await api.get<User>("auth/me");

    return user.data;
  },
  forgotPassword: async (payload: { email: string }) => {
    const response = await api.post("auth/forgot-password", payload);

    if (response.status === 404) {
      return {
        success: false,
        error: {
          name: "Ошибка",
          message: "Такой email не зарегистрирован",
        },
      };
    }
    if (response.status === 201) {
      return {
        success: true,
        redirectTo: "/forgot-password",
      };
    }
    return {
      success: false,
      error: {
        name: "Ошибка",
        message: "Неизвестная ошибка, попробуйте позже",
      },
    };
  },
  updatePassword: async ({ password, confirmPassword, uuid }) => {
    const response = await api.post("auth/update-password", {
      password,
      confirmPassword,
      uuid,
    });
    const { accessToken, refreshToken } = response.data;

    if (accessToken) {
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

      return {
        success: true,
        redirectTo: "/",
      };
    }
    return {
      success: false,
      error: {
        name: "Ошибка",
        message: "Непредвиденная ошибка, попробуйте позже",
      },
    };
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
};
