import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  TagField,
  EmailField,
  BooleanField,
} from "@refinedev/antd";
import { Table, Space, Anchor } from "antd";
import { Link } from "react-router-dom";

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          title={translate("users.fields.id")}
          render={(value: string) => (
            <Link to={`/admin/${value}`}>{value}</Link>
          )}
        />
        <Table.Column
          dataIndex={["email"]}
          title={translate("users.fields.email")}
          render={(value: any) => <EmailField value={value} />}
        />
        <Table.Column
          dataIndex="msToken"
          title={translate("users.fields.msToken")}
        />
        <Table.Column
          dataIndex={["isActive"]}
          title={translate("users.fields.isActive")}
          render={(value: any) => <BooleanField value={value} />}
        />
        <Table.Column dataIndex="role" title={translate("users.fields.role")} />
        <Table.Column
          dataIndex={["canSeeData"]}
          title={translate("users.fields.canSeeData")}
          render={(value: any) => <BooleanField value={value} />}
        />
        <Table.Column
          dataIndex={["canActivateServices"]}
          title={translate("users.fields.canActivateServices")}
          render={(value: any) => <BooleanField value={value} />}
        />
        <Table.Column
          dataIndex="services"
          title={translate("users.fields.services")}
          render={(value: any[]) => (
            <>
              {value?.map((item) => (
                <TagField value={item?.name} key={item?.name} />
              ))}
            </>
          )}
        />
        <Table.Column
          title={translate("table.actions")}
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
