import React, { useRef } from "react";
import { Button, List, Popover, Select, Spin, Tag, Typography } from "antd";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import Form from "@rjsf/antd";
import { IChangeEvent } from "@rjsf/core";
import { UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { TreeComponent } from "../../components/TreeComponent";
import { ProductFolderNode, Service } from "../../entities/service/Service";
import { DefaultService } from "../../entities/service/DefaultService";
import { SharedModal } from "../../shared/ui/SharedModal";
import { getTag } from "../reports/scripts/getTag";
import { useServicePage } from "./useServicePage";
import { useModal } from "@refinedev/core";

const UI_SCHEMA: UiSchema = {
  "ui:submitButtonOptions": {
    // props: { type: "primary" },
    norender: true,
    // submitText: "Сохранить настройки",
  },
};

type ProductFolder = { key: string; title: string };

interface ServiceSettingsProps {
  currentService: Service;
  productFolders: ProductFolder[];
  setProductFolders: React.Dispatch<React.SetStateAction<ProductFolder[]>>;
  setForm: React.Dispatch<React.SetStateAction<any>>;
  isCurrentServiceLoading: boolean;
  removeAllProductFolders: () => void;
}

interface ServiceHistoryProps {
  serviceHistory: any;
  currentService: Service;
  stopService: (serviceId: number, serviceHistoryId: number) => void;
  isLoadingServiceHistory: number;
  defaultService: DefaultService;
}

const ServiceSettings: React.FC<ServiceSettingsProps> = ({
  currentService,
  productFolders,
  setProductFolders,
  setForm,
  isCurrentServiceLoading,
  removeAllProductFolders,
}) => {
  const productFoldersModal = useModal();

  return (
    <>
      <Form
        uiSchema={UI_SCHEMA}
        schema={currentService.schema}
        validator={validator}
        onChange={(e: IChangeEvent) => setForm(e.formData)}
      >
        {/* <Button htmlType="submit" type="primary">
          Сохранить
        </Button> */}
      </Form>
      <ProductFoldersSection
        productFolders={productFolders}
        onModalShow={productFoldersModal.show}
        onRemoveAll={removeAllProductFolders}
      />

      <ProductFoldersModal
        isVisible={productFoldersModal.visible}
        onClose={productFoldersModal.close}
        currentService={currentService}
        productFolders={productFolders}
        setProductFolders={setProductFolders}
        isLoading={isCurrentServiceLoading}
      />
    </>
  );
};

const ProductFoldersSection: React.FC<{
  productFolders: ProductFolder[];
  onModalShow: () => void;
  onRemoveAll: () => void;
}> = ({ productFolders, onModalShow, onRemoveAll }) => (
  <>
    <div
      style={{
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        marginTop: "1rem",
      }}
    >
      <Typography.Title level={5} style={{ marginBottom: 0 }}>
        Товары
      </Typography.Title>
      <Button onClick={onModalShow}>Изменить товары</Button>
      <Button onClick={onRemoveAll} danger>
        Очистить
      </Button>
    </div>
    <Select
      mode="multiple"
      open={false}
      removeIcon={<span></span>}
      suffixIcon={<span></span>}
      allowClear={false}
      dropdownStyle={{ display: "none" }}
      style={{ width: "100%", marginTop: "0.5rem" }}
      value={productFolders.map((folder) => ({
        value: folder.key,
        label: folder.title,
      }))}
    >
      {/* {productFolders.map((folder) => (
        <Select.Option
          key={folder.key}
          // style={{
          //   marginRight: "0.5rem",
          //   backgroundColor: "gray",
          //   padding: "0.3rem",
          // }}
        >
          {folder.title}
        </Select.Option>
      ))} */}
    </Select>
    {/* <List>
      {productFolders.map((folder) => (
        <List.Item key={folder.key}>{folder.title}</List.Item>
      ))}
    </List> */}
  </>
);

const ProductFoldersModal: React.FC<{
  isVisible: boolean;
  onClose: () => void;
  currentService: Service;
  productFolders: ProductFolder[];
  setProductFolders: React.Dispatch<React.SetStateAction<ProductFolder[]>>;
  isLoading: boolean;
}> = ({
  isVisible,
  onClose,
  currentService,
  productFolders,
  setProductFolders,
  isLoading,
}) => (
  <SharedModal
    closeModal={onClose}
    isVisible={isVisible}
    handleSubmit={() => Promise.resolve()}
    title={currentService.name}
    okText="Продолжить"
  >
    <Spin spinning={isLoading}>
      <TreeComponent
        alreadySelectedNodes={productFolders}
        handleContinue={(savedNodes) => {
          setProductFolders(savedNodes);
          onClose();
        }}
      />
    </Spin>
  </SharedModal>
);

const ServiceHistory: React.FC<ServiceHistoryProps> = ({
  serviceHistory,
  currentService,
  stopService,
  isLoadingServiceHistory,
  defaultService,
}) => (
  <List className="demo-loadmore-list" itemLayout="horizontal">
    {serviceHistory.data?.data.map((historyItem: any) => (
      <ServiceHistoryItem
        key={historyItem.id}
        historyItem={historyItem}
        currentService={currentService}
        stopService={stopService}
        isLoadingServiceHistory={isLoadingServiceHistory}
        defaultService={defaultService}
      />
    ))}
  </List>
);

const ServiceHistoryItem: React.FC<{
  historyItem: any;
  currentService: Service;
  stopService: (serviceId: number, serviceHistoryId: number) => void;
  isLoadingServiceHistory: number;
  defaultService: DefaultService;
}> = ({
  historyItem,
  currentService,
  stopService,
  isLoadingServiceHistory,
  defaultService,
}) => {
  const dateString = new Intl.DateTimeFormat("ru-RU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(new Date(historyItem.createdAt));

  const tag = getTag(historyItem.status);

  const actions = getHistoryItemActions(
    historyItem,
    currentService,
    stopService,
    isLoadingServiceHistory
  );

  return (
    <List.Item
      style={{
        backgroundColor: "white",
        padding: "1rem",
        marginBottom: "1.5rem",
        borderRadius: "10px",
      }}
      actions={actions}
    >
      <List.Item.Meta title={defaultService.name} />
      <div style={{ display: "flex", gap: "1rem" }}>
        <HistoryItemPopover historyItem={historyItem} />
        {historyItem.status === "active" && <Spin />}
        {historyItem.status !== "done" && <div>{historyItem.percent}%</div>}
        {tag && <Tag color={tag.color}>{tag.status}</Tag>}
        <div>{dateString}</div>
      </div>
    </List.Item>
  );
};

const getHistoryItemActions = (
  historyItem: any,
  currentService: Service,
  stopService: (serviceId: number, serviceHistoryId: number) => void,
  isLoadingServiceHistory: number
) => {
  if (historyItem.status === "active") {
    return [
      <Button
        loading={isLoadingServiceHistory === historyItem.id}
        key={historyItem.id}
        onClick={() => stopService(currentService.id, historyItem.id)}
      >
        Отменить
      </Button>,
    ];
  } else if (historyItem.status === "done" && historyItem.link) {
    return [
      <Button key={historyItem.id} target="_blank" href={historyItem.link}>
        Открыть
      </Button>,
    ];
  }
  return [];
};

const HistoryItemPopover: React.FC<{ historyItem: any }> = ({
  historyItem,
}) => (
  <Popover
    content={
      <div>
        <PopoverSection
          title="Выбранные категории"
          items={historyItem.productFolders}
        />
        {historyItem.settings?.from && historyItem.settings?.to && (
          <PopoverSection
            title="Даты"
            items={[
              `От ${formatDate(historyItem.settings.from)}`,
              `До ${formatDate(historyItem.settings.to)}`,
            ]}
          />
        )}
        <PopoverSection
          title="Склады"
          items={historyItem.settings?.storeNames}
        />
      </div>
    }
  >
    <QuestionCircleTwoTone />
  </Popover>
);

const PopoverSection: React.FC<{ title: string; items: any[] }> = ({
  title,
  items,
}) => (
  <>
    <p style={{ fontWeight: "bold" }}>{title}</p>
    {items?.map((item, index) => (
      <p key={index}>{typeof item === "string" ? item : item.title}</p>
    ))}
  </>
);

const formatDate = (date: string) =>
  new Intl.DateTimeFormat("ru-RU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).format(new Date(date));

export const ServicePage: React.FC = () => {
  const {
    form,
    setForm,
    currentService,
    isCurrentServiceLoading,
    isLoadingServiceHistory,
    productFolders,
    setProductFolders,
    defaultService,
    serviceHistory,
    startService,
    stopService,
    removeAllProductFolders,
  } = useServicePage();

  const historyItemsRef = useRef<HTMLDivElement>(null);

  const handleStartService = async () => {
    if (currentService) {
      await startService(currentService.id);
      // Прокрутка к элементу с id "history-items"
      historyItemsRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Переместите все условные рендеринги сюда
  if (!defaultService || !currentService) {
    return (
      <Spin
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100dvh",
        }}
      />
    );
  }

  return (
    <div style={{ backgroundColor: "transparent", padding: "1rem" }}>
      <div
        style={{
          backgroundColor: "white",
          padding: "1.5rem",
          marginBottom: "1rem",
          borderRadius: "20px",
        }}
      >
        <div style={{ display: "flex", gap: "1rem" }}>
          <Typography.Title level={3}>{defaultService.name}</Typography.Title>
          <Button
            loading={isCurrentServiceLoading}
            size="middle"
            type="primary"
            onClick={handleStartService}
          >
            Запустить перерасчёт
          </Button>
        </div>
        <Typography.Title level={4}>Настройки</Typography.Title>

        <ServiceSettings
          currentService={currentService}
          productFolders={productFolders}
          setProductFolders={setProductFolders}
          setForm={setForm}
          isCurrentServiceLoading={isCurrentServiceLoading}
          removeAllProductFolders={removeAllProductFolders}
        />
      </div>

      <div
        style={{
          backgroundColor: "white",
          padding: "1.5rem",
          borderRadius: "20px",
        }}
      >
        <Typography.Title level={4}>История</Typography.Title>
        <div id="history-items" ref={historyItemsRef}>
          <ServiceHistory
            serviceHistory={serviceHistory}
            currentService={currentService}
            stopService={stopService}
            isLoadingServiceHistory={isLoadingServiceHistory}
            defaultService={defaultService}
          />
        </div>
      </div>
    </div>
  );
};
