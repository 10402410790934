import { useCreate } from "@refinedev/core";

export const useStopService = () => {
  const { mutateAsync: create } = useCreate();

  return (serviceId: number) =>
    create({
      resource: `services/stop?service-id=${serviceId}`,
      values: {},
      successNotification: false,
      errorNotification: (error) => {
        console.error(error, "error after stop");
        return {
          type: "error",
          message: error?.message ?? "Непредвиденная ошибка",
        };
      },
    });
};
