import {
  useList,
  useNotification as usePopup,
  useUpdateMany,
} from "@refinedev/core";
import { useState } from "react";
import { Notification } from "./Notification";

export const useNotifications = () => {
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const popup = usePopup();

  const { mutateAsync } = useUpdateMany();
  const readAllNotifications = () => {
    mutateAsync({
      resource: "notifications",
      ids: notifications.map((n) => n.id),
      values: { isRead: true },
      successNotification: false,
    }).then(() => {
      setNotificationsCount(0);
    });
  };

  const notificationsQuery = useList<Notification>({
    resource: "notifications",
    queryOptions: {
      refetchOnReconnect: true,
      refetchInterval: 5_000,
      onSuccess: (data) => {
        const unreadNotifications = data.data?.filter((n) => !n.isRead);

        // unreadNotifications.forEach((notification) => {
        //   if (popup.open) {
        //     popup.open({
        //       message: notification.message,
        //       type: "success",
        //     });
        //   }
        // });

        setNotificationsCount(unreadNotifications.length);
        setNotifications(data.data);
      },
    },
  });

  return {
    notifications: notificationsQuery.data?.data ?? [],
    notificationsCount,
    readAllNotifications,
  };
};
