import { BellOutlined } from "@ant-design/icons";
import { Badge, Button, Drawer, Flex, List, Typography } from "antd";
import { useState } from "react";
import { useNotifications } from "../../../entities/notification/useNotifications";

// const data = ["Уведомление #1", "Уведомление #2"];
export const NotificationsButton = () => {
  const [open, setOpen] = useState(false);
  const { notifications, notificationsCount, readAllNotifications } =
    useNotifications();

  const handleOpen = () => {
    setOpen(true);
    readAllNotifications();
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Badge count={notificationsCount}>
        <Button
          icon={<BellOutlined />}
          shape="circle"
          onClick={handleOpen}
        ></Button>
      </Badge>
      <Drawer
        title="Уведомления"
        placement="right"
        closable={true}
        onClose={onClose}
        open={open}
      >
        <List
          dataSource={notifications ?? []}
          renderItem={(item) => {
            const moment = new Date(item.moment);

            const momentString = new Intl.DateTimeFormat("ru", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              month: "short",
              day: "numeric",
            }).format(moment);

            return (
              <List.Item
                style={{ flexDirection: "column", alignItems: "stretch" }}
              >
                <Typography.Paragraph
                  style={{ marginBottom: 0, fontWeight: "bold" }}
                >
                  {item.message}
                </Typography.Paragraph>
                <Flex align="center" justify="space-between">
                  <Typography.Text
                    style={{ textAlign: "right", fontSize: "0.8rem" }}
                  >
                    {momentString}
                  </Typography.Text>
                  {item.link && item.linkText && (
                    <Button download href={item.link}>
                      {item.linkText}
                    </Button>
                  )}
                </Flex>
              </List.Item>
            );
          }}
        />
      </Drawer>
    </>
  );
};
