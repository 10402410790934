import { Refine } from "@refinedev/core";
import { RefineKbar } from "@refinedev/kbar";

import { useNotificationProvider } from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import routerBindings, {
  DocumentTitleHandler,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { Providers } from "./processes/Providers";
import { Router } from "./processes/Router";
import { authProvider } from "./processes/authProvider";
import { resources } from "./processes/resources";
import { useAccessControlProvider } from "./processes/useAccessControlProvider";
import { useTranslateProvider } from "./processes/useTranslateProvider";
import { api } from "./shared";

function App() {
  const accessControlProvider = useAccessControlProvider();
  const i18nProvider = useTranslateProvider();

  return (
    <Providers>
      <Refine
        i18nProvider={i18nProvider}
        accessControlProvider={accessControlProvider}
        dataProvider={dataProvider("", api)}
        notificationProvider={useNotificationProvider}
        routerProvider={routerBindings}
        authProvider={authProvider}
        resources={resources}
        options={{
          syncWithLocation: true,
          warnWhenUnsavedChanges: true,
          useNewQueryKeys: true,
          projectId: "86BzhE-i7gf3m-JNTHu9",
        }}
      >
        <Router />
        <RefineKbar />
        <UnsavedChangesNotifier />
        <DocumentTitleHandler />
      </Refine>
    </Providers>
  );
}

export default App;
